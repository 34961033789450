var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:{skeleton: _vm.skeleton}},[(_vm.tableData)?_c('div',{staticClass:"table"},[_c('p',{staticClass:"scanned-at"},[_vm._v(" Scanned at "+_vm._s(_vm.scanDate)+" ("+_vm._s(_vm.days)+") ")]),_c('div',{staticClass:"table__column name"},[_c('p',{staticClass:"title"},[_vm._v("Name")]),_vm._l((_vm.tableData),function(hotel,i){return _c('div',{key:i + 'name',staticClass:"hotel-name",on:{"click":function($event){return _vm.$emit('legend:click', hotel.id)}}},[(!_vm.skeleton)?_c('div',{class:{
                        'graph-legend': true,
                        'graph-legend--hidden': _vm.hiddenGraphs[hotel.id],
                    },style:({
                        '--indicator-color': hotel.borderColor
                    })}):_vm._e(),_c('p',{class:{
                        'hotel-name__value': true,
                        'hotel-name__value--my' : hotel.myHotel
                    },attrs:{"title":hotel.name}},[_c('span',{class:{ skeleton: _vm.skeleton }},[_vm._v(_vm._s(hotel.name))])])])})],2),_c('div',{staticClass:"table__column position"},[_c('p',{staticClass:"title"},[_vm._v("Position")]),_vm._l((_vm.tableData),function(hotel,i){return _c('div',{key:i + '_position',class:{
                    'position__value': true,
                    'position__value--my' : hotel.myHotel
                }},[(hotel.position !== -1 && hotel.price !== null)?_c('p',[_c('span',{class:{ skeleton: _vm.skeleton }},[_vm._v(" "+_vm._s(hotel.position)+" ")])]):(hotel.position === null)?_c('p',[_c('span',{class:{ skeleton: _vm.skeleton }},[_vm._v("N/A")])]):_c('p',[_c('span',{class:{ skeleton: _vm.skeleton }},[_vm._v("---")])])])})],2)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }