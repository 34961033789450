

import {
    Component,
    Vue,
    Prop,
} from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import moment from 'moment';

import type Day from '@/modules/common/types/day.type';
import CURRENT_HOTEL_GRAPH_COLOR from '@/modules/common/constants/current-hotel-graph-color.constant';
import MarketsHistoryService, { MarketsHistoryServiceS } from '@/modules/common/modules/markets-history/markets-history.service';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';

export interface TableData {
    id: string,
    name: string,
    position: number,
    myHotel: boolean,
    borderColor?: string,
}

@Component
export default class RatesPriceHistoryTable extends Vue {
    @Inject(MarketsHistoryServiceS)
    private marketsHistoryService!: MarketsHistoryService;

    @Inject(HotelsServiceS)
    private hotelsService!: HotelsService;

    @Inject(UserServiceS)
    private userService!: UserService;

    @Prop({
        type: Number,
        default: null,
    })
    private tableDay!: Day | null;

    @Prop({
        type: Object,
        default: () => ({}),
    })
    public hiddenGraphs!: { [hid: number]: boolean };

    get isChainPage() {
        const { name } = this.$route;

        if (!name) return false;

        return name.includes('chain.hotel.compset')
            || name.includes('cluster.markets.day-markets')
            || name.includes('chain.markets.day-markets');
    }

    get compsetId() {
        return this.isChainPage
            ? this.$route.params.compsetId
            : null;
    }

    get skeleton() {
        return this.marketsHistoryService.isLoading;
    }

    get currentHotelId() {
        return Number(this.$route.params.hotelId) || this.userService.currentHotelId;
    }

    get scanDate() {
        const { lastScanDate, dayIndex } = this.marketsHistoryService;
        const date = new Date(lastScanDate || 0);

        date.setDate(date.getDate() - dayIndex);
        return moment(date).format('DD.MM');
    }

    get days() {
        const { dayIndex } = this.marketsHistoryService;
        return !dayIndex ? 'Last update' : `-${dayIndex} days`;
    }

    get tableData() {
        const { compsetId } = this;
        const { marketHistoryHotels, marketsDocument, dayIndex } = this.marketsHistoryService;
        const hotelMarket = this.marketsHistoryService.getSuitableMarketByDay(dayIndex);

        if (!marketHistoryHotels || !marketsDocument) {
            return null;
        }

        const tablePriceHistoryData = marketHistoryHotels.map((hotelId: number) => {
            if (!hotelMarket) {
                return {} as TableData;
            }

            const isMyHotel = hotelId === this.currentHotelId;
            const currentHotelMarket = hotelMarket[hotelId];
            const name = this.hotelsService.getHotelName(hotelId) || hotelId;
            const borderColor = isMyHotel
                ? CURRENT_HOTEL_GRAPH_COLOR
                : this.marketsHistoryService.getHotelColor(hotelId, compsetId);

            if (!currentHotelMarket) {
                return {
                    id: String(hotelId),
                    name,
                    position: -1,
                    myHotel: isMyHotel,
                    borderColor,
                } as TableData;
            }

            return {
                id: String(hotelId),
                name,
                position: currentHotelMarket.position,
                myHotel: isMyHotel,
                borderColor,
            } as TableData;
        });

        return tablePriceHistoryData.sort((a, b) => {
            if (a.position < 0) return 1;
            if (b.position < 0) return -1;
            return a.position - b.position;
        });
    }
}
